import React from "react"
import styled from "@emotion/styled"
import { TextContainer } from '../components/common'
import { Link } from "gatsby"



export default () =>{ 
    const Bg404 = styled.div`
        height: 800px;
        width: 100vw;
        background-color: #f6f6f6;

        h1{
            color: #333;
            text-align: center;
            font-size: 7vw;
            margin-top: 4rem;
            margin-bottom: 1rem;
        }

        p{
            color: #888;
            font-size: 1.5vw;
            text-align: center;
        }
        h4{
            color: #555;
            font-size: 1.5vw;
            text-align: center;
        }
        
        @media (max-width: 600px) {
            h1{
                font-size: 10vw;
            }
            h4{
                font-size: 3vw;
            }
            p{
                font-size: 3vw;
            }
        }
    `

    return(
        <Bg404>
            <TextContainer style={{border:'1px solid #d6d6d6', backgroundColor:'white', padding: '4rem'}}>
                <h1>404</h1>
                <p style={{margin:'0 auto', color:"#999"}}>Error - Page Not Found</p>
                <div style={{width:'100%', border:'1px solid #d6d6d6', margin:'2rem auto'}}/>
                <h4>Please check the URL</h4>
                <p style={{margin:'0 auto 4rem auto'}}>Otherwise, <Link to='/' style={{color:'#0090e6', textDecoration:'none'}}> click here</Link> to be redirected to the homepage.</p>
            </TextContainer>
        </Bg404>
    )
}
