import styled from "@emotion/styled"
// import { css } from "@emotion/core"
// import { Link, navigate } from "gatsby"


const Actionbutton = styled.button`
    display: block;
    width: max-content;
    background-color: transparent;
    cursor: pointer; 
    color: #666;
    box-shadow: none;
    padding: 3px  30px 3px 30px; 
    outline: 0;
    border: 2px solid #0099df;
    line-height: 160%;
    font-size: 1.3rem;

    width: 18rem;
    transition: 0.3s;

    :hover{
        border-radius: 25px;
    }

    @media (max-width: 600px) {
        border-radius: 5px;
    }
`

const ActionbuttonInverted = styled.button`
    display: block;
    width: max-content;
    background-color: transparent;
    cursor: pointer; 
    color: white;
    box-shadow: none;
    padding: 3px  30px 3px 30px; 
    outline: 0;
    font-size: 1.3rem;
    border: 2px solid white;
    
    width: 18rem;
    transition: 0.3s;
    
    :hover{
        border-radius: 25px;
    }

    @media (max-width: 600px) {
        border-radius: 5px;
    }

`

const TextContainer = styled.div`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: max-content;

`

const TextContainer2 = styled.div`
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 100%;
`


// width: max-content;
const SquareButton = styled.button`
    display: block;
    background-color: transparent;
    cursor: pointer; 
    color: #888;
    box-shadow: none;
    padding: 3px  30px 3px 30px; 
    outline: 0;
    border: 2px solid #888;
    font-size: 1.3rem;
    transition: 0.3s;
    width: 18rem;


    :hover{
        border-radius: 25px;
    }

    @media (max-width: 600px) {
        border-radius: 5px;
    }
`


const Body = styled.div`
    h1{
        margin-left: 0;
        margin-right: 0;
        margin-top: 6rem;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        margin-bottom: 1rem;
        font-weight: 700;
        text-rendering: optimizeLegibility;
        font-size: 3.4rem;
        line-height: 1;
    }

    h2 {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        margin-bottom: 1rem;
        font-weight: 700;
        text-rendering: optimizeLegibility;
        font-size: 2.4rem;
        line-height: 1;
    }
    h3 {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        margin-bottom: 1rem;
        font-weight: 700;
        text-rendering: optimizeLegibility;
        font-size: 1.8rem;
        line-height: 1;
    }
    p {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        margin-bottom: 4rem;
        font-size: 1.4rem;
        line-height: 140%;
    }
    form {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        margin-bottom: 1rem;
    }
    @media (max-width: 600px) {
        h1{
            text-align: center;
            font-size: 3rem;
            margin-top: 4rem;
            margin-bottom: 0.5rem;
        }
        h2{
            margin-bottom: 0.5rem;
            font-size: 2rem;
            text-align: center;
        }
        p{
            margin-bottom: 1rem;
            font-size: 1.4rem;
            line-height: 125%;
            text-align: center;
        }
    }
`

export{
    Actionbutton,
    TextContainer,
    ActionbuttonInverted,
    SquareButton,
    Body,
    TextContainer2
}